import React from "react";
// import Typed from "react-typed";
import "./hero.css";


const Hero = () => {
  return (
    <div className="" id="home">
      <div className=" w-full h-screen mx-auto text-white bg-cover md: bg-top h-[72vh]">
 
      </div>
     
    </div>
  );
};

export default Hero;
